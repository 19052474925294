/* You can add global styles to this file, and also import other style files */
@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "swiper/scss/grid";
@import "swiper/scss/virtual";
@import "/src/shared/font.scss";


body {
  margin: 0;
  font-family: segmdl2, "Roboto", sans-serif;
  font-size: 16px;
  color: #333;
}

:root {
  --swiper-navigation-size: 20px;
  --swiper-navigation-color: gray;
  --swiper-theme-color: #8BC34A;
  --primary-theme-color: #8BC34A;
}

app-page-not-found {
  flex: 1 !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: #fff;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: #fff;
}

.mat-mdc-unelevated-button:not(:disabled) {
  color: white !important;
}

.global-container {
  margin: 0 10px;
  width: calc(100% - 20px);
  height: 100%;
  padding-bottom: 10px;

  &--without-mx {
    margin: 0;
    width: 100%;
  }

  @media screen and (min-width:991px) {
    width: 80%;
    margin: 0 auto;
  }
}

// Scratch Coupon Card

#js--sc--container {
  max-width: 300px;
  width: 300px;
  height: 300px;
  margin: auto;
  position: relative;

  canvas {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99;
    border-radius: 5px;
  }

  .sc__inner {
    background-color: #ffffff;
    height: 99%;
    width: 99%;
    border-radius: 5px;
  }
}

.gift-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .heading {
    font-family: Georgia;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    margin: 0;
    color: #027876;
  }

  .description {
    margin: 0;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
  }
}

.coupon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .coupon-code {
    margin-top: 15px;
    background-color: rgb(252, 219, 6);
    border-radius: 25px;
    padding: 15px 20px;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);

    span {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .coupon-validity {
    margin-top: 4px;
    font-size: 12px;
  }
}