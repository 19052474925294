@font-face {
  font-family: 'muli';
  src: url('/assets/fonts/muli/Muli.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-bold';
  src: url('/assets/fonts/muli/Muli-Bold.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-Bold.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-bold-italic';
  src: url('/assets/fonts/muli/Muli-BoldItalic.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-BoldItalic.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-extra-light';
  src: url('/assets/fonts/muli/Muli-ExtraLight.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-ExtraLight.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-extra-light-italic';
  src: url('/assets/fonts/muli/Muli-ExtraLightItalic.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-ExtraLightItalic.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-italic';
  src: url('/assets/fonts/muli/Muli-Italic.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-Italic.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-light';
  src: url('/assets/fonts/muli/Muli-Light.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-Light.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-light-italic';
  src: url('/assets/fonts/muli/Muli-LightItalic.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-LightItalic.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-semi-bold-italic';
  src: url('/assets/fonts/muli/Muli-Semi-BoldItalic.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-Semi-BoldItalic.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'muli-semi-bold';
  src: url('/assets/fonts/muli/Muli-Semi-Bold.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/muli/Muli-Semi-Bold.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'segmdl2';
  src: url('/assets/fonts/segmdl2.ttf') format('woff2'),
    /* Modern Browsers */
    url('/assets/fonts/segmdl2.ttf') format('woff');
  /* Older Browsers */
  font-weight: normal;
  font-style: normal;
}